import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import InteriorHero from "../components/interior-components/InteriorHero";
import GenericCopy from "../components/interior-components/GenericCopy";
import LowerSection from "../components/interior-components/LowerSection";
import MapSection from "../components/interior-components/MapSection";

// eslint-disable-next-line
export const LocationsPostTemplate = ({
    contentComponent,
    loc_heroHeadline,
    loc_showTitle,
    loc_thirdLevel,
    loc_title,
    loc_heroImage,
    loc_romance,
    loc_lowerSection,
    loc_map_section,
  }) => {
    const PostContent = contentComponent || Content;

    /**
     * TEMPLATE MARKUP START
     */
    return (
      <div className="main-page-content">
        {/* Required Blocks */}
        <InteriorHero image={loc_heroImage} title={loc_title} heroHeadline={loc_heroHeadline} showTitle={loc_showTitle} thirdLevel={loc_thirdLevel} />
        <GenericCopy copy={loc_romance.romance_copy} image={loc_romance.image_1} image_second={loc_romance.image_2} />

        {/* Optional Blocks */}
        { loc_map_section.heading &&
            <MapSection heading={loc_map_section.heading} copy={loc_map_section.copy} location={loc_map_section.location}/>
        }
        { loc_lowerSection.copy &&
            <LowerSection image={loc_lowerSection.image} copy={loc_lowerSection.copy}  col1={loc_lowerSection.col1}  col2={loc_lowerSection.col2} />
        }
      </div>
    );
};

LocationsPostTemplate.propTypes = {
    loc_heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    loc_title: PropTypes.string,
    loc_showTitle: PropTypes.bool,
    loc_thirdLevel: PropTypes.bool,
    loc_heroHeadline: PropTypes.string,
    loc_romance: PropTypes.object,
    loc_lowerSection: PropTypes.object,
    loc_map_section: PropTypes.object,
};

const LocationsPost = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
      <Layout>
        <LocationsPostTemplate
          content={post.html}
          contentComponent={HTMLContent}
          description={post.frontmatter.description}
          helmet={
            <Helmet titleTemplate="%s | Service Areas">
              <title>{`${post.frontmatter.title}`}</title>
              <meta
                name="description"
                content={`${post.frontmatter.description}`}
              />
            </Helmet>
          }
          loc_title={post.frontmatter.loc_title}
          loc_heroHeadline={post.frontmatter.loc_heroHeadline}
          loc_showTitle={post.frontmatter.loc_showTitle}
          loc_thirdLevel={post.frontmatter.loc_thirdLevel}
          loc_romance={post.frontmatter.loc_romance}
          loc_heroImage={post.frontmatter.loc_heroImage}
          loc_lowerSection={post.frontmatter.loc_lowerSection}
          loc_map_section={post.frontmatter.loc_map_section}
        />
      </Layout>
    );
  };
  
  LocationsPost.propTypes = {
    data: PropTypes.shape({
      markdownRemark: PropTypes.object,
    }),
  };
  
  export default LocationsPost;

export const pageQuery = graphql`
  query LocationsQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        loc_title
        loc_heroHeadline
        loc_showTitle
        loc_thirdLevel
        loc_heroImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        loc_romance{
          image_1{
            img{
              childImageSharp {
                gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
              }
            }
            alt
          }
          image_2{
            img{
              childImageSharp {
                gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
              }
            }
            alt
          }
          romance_copy
        }
        loc_map_section{
            heading
            copy
            location
        }
        loc_lowerSection{
            image{
                img{
                    childImageSharp {
                        gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
                    }
                }
                alt
            }
            copy
            col1
            col2
        }
      }
    }
  }
`;

