import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

export default function MapSection(props) {
  const { heading, copy, location } = props;

  const location_json = JSON.parse(location);

  return (
    <>
      <section className="section pt-0 pb-0 map_section">
        <div className="container">
          <div className="section pt-0 pb-0">
            <div className="columns is-gapless">
              {heading && (
                <div className="column is-half">
                  <div className="map_section__copy">
                    <h1 className="map_section__title">{heading}</h1>
                    {copy && (
                      <div className="map_section__copy_main">
                        <ReactMarkdown
                          children={copy}
                          rehypePlugins={[rehypeRaw]}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {location && (
                <div className="column is-half">
                  <div className="map_section__map">
                    <div className="mapouter">
                      <div className="gmap_canvas">
                        <iframe
                          src={`https://maps.google.com/maps?q=${location_json.coordinates[1]},${location_json.coordinates[0]}&z=11&ie=UTF8&iwloc=B&output=embed`}
                          className="gmap_iframe"
                          title="Google maps"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
